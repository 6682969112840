import { useAuthStore } from '../stores/authStore'
export default defineNuxtRouteMiddleware((to,from) => {
  
    const authStore = useAuthStore();


    if (!authStore.isLoggedIn && to.path !== '/login' && !to.path.startsWith('/resetar-senha') && to.path !== '/esqueci-minha-senha') {
      // Redirect to login page if not logged in
      return navigateTo('/login');
    } else if (authStore.isLoggedIn && useAuthStore().first_login && to.path !== '/alterar-senha') {
      return navigateTo('/alterar-senha')
    } 
    else if (authStore.isLoggedIn && to.path === '/login' && !useAuthStore().first_login) {
      return navigateTo('/campanhas')
    } 
  });