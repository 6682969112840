// app.config.ts
export default defineAppConfig({
  title: 'Md1 sender',

  nuxtIcon: {
    size: '24px', // default <Icon> size applied
    class: 'vueIcon', // default <Icon> class applied
    aliases: {
      'nuxt': 'logos:nuxt-icon',
    }
  },
  ui: {
    notifications: {
      position: 'top-5 bottom-auto',

    },
    toogle: {
      class: 'toogle-class',
    },
    tooltip:{
      color:'text-gray-900 dark:text-white'
    }
  }
})