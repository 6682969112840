import { default as indexKcVys1NPNvMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/agendamentos/index.vue?macro=true";
import { default as indexPPZfLgWvo3Meta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/ajuda/index.vue?macro=true";
import { default as alterar_45senhaCXQBgvkmqBMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/alterar-senha.vue?macro=true";
import { default as _91id_93FMeRbVpE5iMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/campanha/[id].vue?macro=true";
import { default as indexFx2uBWq3dAMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/campanhas/index.vue?macro=true";
import { default as index1AZKST7auNMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/coletar/index.vue?macro=true";
import { default as indexZEHozRe40vMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/disparo/index.vue?macro=true";
import { default as indexXsyxXekCEPMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/disparos/index.vue?macro=true";
import { default as esqueci_45minha_45senhar619WsUnMyMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/esqueci-minha-senha.vue?macro=true";
import { default as index1yPwkrdz9WMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/index.vue?macro=true";
import { default as login9eZkT7CaGlMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/login.vue?macro=true";
import { default as indexCvRPW6u7K2Meta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/preferencias/index.vue?macro=true";
import { default as _91token_93U2UJiRGegVMeta } from "C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/resetar-senha/[uid]/[token].vue?macro=true";
export default [
  {
    name: indexKcVys1NPNvMeta?.name ?? "agendamentos",
    path: indexKcVys1NPNvMeta?.path ?? "/agendamentos",
    meta: indexKcVys1NPNvMeta || {},
    alias: indexKcVys1NPNvMeta?.alias || [],
    redirect: indexKcVys1NPNvMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/agendamentos/index.vue").then(m => m.default || m)
  },
  {
    name: indexPPZfLgWvo3Meta?.name ?? "ajuda",
    path: indexPPZfLgWvo3Meta?.path ?? "/ajuda",
    meta: indexPPZfLgWvo3Meta || {},
    alias: indexPPZfLgWvo3Meta?.alias || [],
    redirect: indexPPZfLgWvo3Meta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: alterar_45senhaCXQBgvkmqBMeta?.name ?? "alterar-senha",
    path: alterar_45senhaCXQBgvkmqBMeta?.path ?? "/alterar-senha",
    meta: alterar_45senhaCXQBgvkmqBMeta || {},
    alias: alterar_45senhaCXQBgvkmqBMeta?.alias || [],
    redirect: alterar_45senhaCXQBgvkmqBMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/alterar-senha.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FMeRbVpE5iMeta?.name ?? "campanha-id",
    path: _91id_93FMeRbVpE5iMeta?.path ?? "/campanha/:id()",
    meta: _91id_93FMeRbVpE5iMeta || {},
    alias: _91id_93FMeRbVpE5iMeta?.alias || [],
    redirect: _91id_93FMeRbVpE5iMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/campanha/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFx2uBWq3dAMeta?.name ?? "campanhas",
    path: indexFx2uBWq3dAMeta?.path ?? "/campanhas",
    meta: indexFx2uBWq3dAMeta || {},
    alias: indexFx2uBWq3dAMeta?.alias || [],
    redirect: indexFx2uBWq3dAMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/campanhas/index.vue").then(m => m.default || m)
  },
  {
    name: index1AZKST7auNMeta?.name ?? "coletar",
    path: index1AZKST7auNMeta?.path ?? "/coletar",
    meta: index1AZKST7auNMeta || {},
    alias: index1AZKST7auNMeta?.alias || [],
    redirect: index1AZKST7auNMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/coletar/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHozRe40vMeta?.name ?? "disparo",
    path: indexZEHozRe40vMeta?.path ?? "/disparo",
    meta: indexZEHozRe40vMeta || {},
    alias: indexZEHozRe40vMeta?.alias || [],
    redirect: indexZEHozRe40vMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/disparo/index.vue").then(m => m.default || m)
  },
  {
    name: indexXsyxXekCEPMeta?.name ?? "disparos",
    path: indexXsyxXekCEPMeta?.path ?? "/disparos",
    meta: indexXsyxXekCEPMeta || {},
    alias: indexXsyxXekCEPMeta?.alias || [],
    redirect: indexXsyxXekCEPMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/disparos/index.vue").then(m => m.default || m)
  },
  {
    name: esqueci_45minha_45senhar619WsUnMyMeta?.name ?? "esqueci-minha-senha",
    path: esqueci_45minha_45senhar619WsUnMyMeta?.path ?? "/esqueci-minha-senha",
    meta: esqueci_45minha_45senhar619WsUnMyMeta || {},
    alias: esqueci_45minha_45senhar619WsUnMyMeta?.alias || [],
    redirect: esqueci_45minha_45senhar619WsUnMyMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/esqueci-minha-senha.vue").then(m => m.default || m)
  },
  {
    name: index1yPwkrdz9WMeta?.name ?? "index",
    path: index1yPwkrdz9WMeta?.path ?? "/",
    meta: index1yPwkrdz9WMeta || {},
    alias: index1yPwkrdz9WMeta?.alias || [],
    redirect: index1yPwkrdz9WMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login9eZkT7CaGlMeta?.name ?? "login",
    path: login9eZkT7CaGlMeta?.path ?? "/login",
    meta: login9eZkT7CaGlMeta || {},
    alias: login9eZkT7CaGlMeta?.alias || [],
    redirect: login9eZkT7CaGlMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexCvRPW6u7K2Meta?.name ?? "preferencias",
    path: indexCvRPW6u7K2Meta?.path ?? "/preferencias",
    meta: indexCvRPW6u7K2Meta || {},
    alias: indexCvRPW6u7K2Meta?.alias || [],
    redirect: indexCvRPW6u7K2Meta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/preferencias/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93U2UJiRGegVMeta?.name ?? "resetar-senha-uid-token",
    path: _91token_93U2UJiRGegVMeta?.path ?? "/resetar-senha/:uid()/:token()",
    meta: _91token_93U2UJiRGegVMeta || {},
    alias: _91token_93U2UJiRGegVMeta?.alias || [],
    redirect: _91token_93U2UJiRGegVMeta?.redirect,
    component: () => import("C:/Users/Desenvolvimento/Documents/Projetos/projeto_sender/sender_nuxt/sender_nuxt/pages/resetar-senha/[uid]/[token].vue").then(m => m.default || m)
  }
]