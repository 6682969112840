export const useSuccessToast = (titulo:string,descricao:string = '') => {
    useToast().add({ 
    title: titulo,
    description: descricao, 
    icon:'i-heroicons-check-circle',
    timeout:5000,
    ui:{
        ring: 'ring-0 ring-green-500 dark:ring-gray-800',
        background:'bg-white',
        title: 'text-md font-medium text-gray-900 dark:text-white',
        rounded: 'rounded',
        default:{

        }
    }
    })
}