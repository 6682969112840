<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="#009D9D" />
    <NuxtPage />

    <UNotifications>
      <template #title="{ title }">
        <span v-html="title" />
      </template>

      <template #description="{ description }">
        <span v-html="description" />
      </template>
    </UNotifications>

  </NuxtLayout>


</template>

<script setup>

onMounted(() => {
  if (useAuthStore().isAuthenticated && !useWppDisparoStore().id) {
    // useWppDisparoStore().getProgressoDisparo()
  }


})

</script>