import { defineStore } from 'pinia';


export const useWppDisparoStore = defineStore({
    id: 'wpp',

    state: () => {

        return {
            disparo: {},
            campanha: null,
        };
    },
    getters: {
        progresso: (state) => calcularProgresso(state.quantidadeDisparada, state.disparo.quantidade_total),
        cancelado: (state) => state.disparo.estado === 'Cancelado',
        disparado: (state) => state.disparo.estado === 'Disparado',
        disparando: (state) => state.disparo.estado === 'Disparando',
        finalizado: (state) => state.disparo.estado === 'Finalizado',
        concluido: (state) => state.disparo.estado === 'Concluído',
        erro: (state) => state.disparo.estado === 'Erro',
        
        quantidadeDisparada: (state) => state.disparo.quantidade_disparada,
        quantidadeValida: (state) => state.disparo.quantidade_valida,
        quantidadeInvalida: (state) => state.disparo.quantidade_invalida,

        contatosValidos: (state) => state.disparo.contatos_validos,
        contatosInvalidos: (state) => {state.disparo.contatos_invalidos}

    },
    actions: {
        getProgressoDisparo(){
            const {$api} = useNuxtApp()
            $api(`api/get-progresso-disparo/`, { method: 'GET' })
                .then(data => {
                    this.setDisparo(data)
                    if(data.estado === 'Disparando'){
                        setTimeout(this.getProgressoDisparo, 5000); // Immediately establish a new long polling request
                    }
                    else if(data.estado === 'Disparado'){
                        // this.reset()
                        useSuccessToast('Disparo Finalizado')
                    } else if (data.estado === 'Erro'){
                        useErrorToast('Erro no disparo')
                    } else {
                        this.resetDisparo()
                    }
                })
                .catch(error => {
                    console.log("Error:", error);
                });
        },
       
        setDisparo(disparo){
            this.disparo = disparo
        },

        resetDisparo(){
            this.disparo = {}
        },

        setCampanha(campanha){
            this.campanha = campanha
        },

        async concluirDisparo(){
            const {$api} = useNuxtApp()
            $api(`api/disparos/${this.disparo.id}/`, {method:'patch', body: {estado: 'Concluído'}}).then(response => {
                this.reset()
            }).catch(error => {
                this.reset()
            });
        },

        reset(){
            this.$reset()
        },

        async cancelarDisparo(){
            const {$api} = useNuxtApp()
            $api(`api/cancelar-disparo/`, {method:'get'}).then(response => {
                this.disparo.estado = 'Cancelado'
                useSuccessToast('Disparo Cancelado!')
            }).catch(error => {});
        },
        reset(){
            this.$reset()
        }
        
    },
    persist: true,
});

function calcularProgresso(quantidadeDisparada, quantidadeTotal) {
    if (!quantidadeDisparada || !quantidadeTotal) return 0;
    return Math.round((quantidadeDisparada / quantidadeTotal) * 100);
}